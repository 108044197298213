import { createSlice } from "@reduxjs/toolkit";
import {
  fetchLiteConfiguratorSchema,
  addLightConfiguration,
  getLightConfigurations,
  fetchLightCMPConfigById,
  deleteLightConfiguration,
} from "../thunks/liteCmpConfig";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const initialState = {
  configurations: [],
  referenceConfig: null,
  filteredConfigurations: [],
  template: null,
  selectedTemplate: null,
  configuration: null,
  mapper: "",
  loading: false,
  redirect: false,
  selectedMapper: null,
  configFolderNameToDelete: null,
  sdkForScript: null,
  bundleForScript: null,
  schema: null,
};

const liteCmpConfigSlice = createSlice({
  name: "liteCmpConfigSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // fetch Lite Configurator Schema
    builder.addCase(fetchLiteConfiguratorSchema.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchLiteConfiguratorSchema.fulfilled, (state, action) => {
      state.loading = false;
      state.schema = action.payload;
    });
    builder.addCase(fetchLiteConfiguratorSchema.rejected, (state, action) => {
      NotificationManager.error("Error occured in fetching schema");
      state.loading = false;
    });

    // add Lite Configuration
    builder.addCase(addLightConfiguration.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addLightConfiguration.fulfilled, (state, action) => {
      state.loading = false;
      // let obj = JSON.parse(action.payload.data);
      // state.selectedTemplate = obj.config;
      // state.selectedMapper = obj.mapper;
    });
    builder.addCase(addLightConfiguration.rejected, (state, action) => {
      NotificationManager.error("error occured");
      state.loading = false;
    });

    // get Lite Configurations
    builder.addCase(getLightConfigurations.pending, (state, action) => {
      state.loading = true;
      state.redirect = false;
    });
    builder.addCase(getLightConfigurations.fulfilled, (state, action) => {
      state.loading = false;
      state.configurations = action.payload;
      state.filteredConfigurations = action.payload;
      state.redirect = false;
    });
    builder.addCase(getLightConfigurations.rejected, (state, action) => {
      NotificationManager.error("error occured");
      state.loading = false;
      state.error = action.payload;
    });

    // fetch Light CMP ConfigById
    builder.addCase(fetchLightCMPConfigById.pending, (state, action) => {
      state.loading = true;
      state.referenceConfig = null;
    });
    builder.addCase(fetchLightCMPConfigById.fulfilled, (state, action) => {
      state.loading = false;
      state.referenceConfig = JSON.parse(action.payload["config"]);
      state.sdkForScript = action.payload["sdkVersion"];
      state.bundleForScript = action.payload["bundleVersion"];
      state.mapper = "";
      state.template = action.payload["template"];
    });
    builder.addCase(fetchLightCMPConfigById.rejected, (state, action) => {
      NotificationManager.error("error occured");
      state.loading = false;
    });

    // delete Light Configuration
    builder.addCase(deleteLightConfiguration.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteLightConfiguration.fulfilled, (state, action) => {
      // NotificationManager.success("Configuration deleted successfully");
      state.loading = false;
      state.configuration = null;
      state.configFolderNameToDelete = null;
    });
    builder.addCase(deleteLightConfiguration.rejected, (state, action) => {
      // NotificationManager.error("error occured");
      state.loading = false;
      state.configuration = null;
    });
  },
});

export default liteCmpConfigSlice.reducer;
