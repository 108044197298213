/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, Switch, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ExpansionPanel from "@mui/material/Accordion";
import ExpansionPanelDetails from "@mui/material/AccordionDetails";
import ExpansionPanelSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as _ from "lodash";
import { useAuth } from "../../contexts/auth";
import { useNavigate, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import Select from "react-select";
import { logOff } from "../../thunks/auth";
// import { Message } from "semantic-ui-react";
// import { customerRegistration, getRegistrationContent } from "../../../actions";
import { customerRegistration } from "../../thunks/registeration";
import { getRegistrationContent } from "../../thunks/help";
import AppConfig from "../../constants/appConfig";
import { Grid } from "@mui/material";
import Feedback from "../../components/Feedback";
const countries = AppConfig.countries;

const coustomdropdownCode = countries.map(function (a) {
  return { value: a.code, label: a.name + " (" + a.dial_code + ")" };
});

const CustomerRegistration = (props) => {
  const { data: authData, setData: setAuthData, logout: logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: "",
    requestedDomains: "",
    primaryEmail: "",
    businessId: "",
    address: "",
    phone: "",
    termsAccepted: false,
    countryCode: "",
    isDomainValid: false,
  });
  const [loadingLocal, setLoading] = useState(false);
  const reducerState = useSelector(({ registrationReducer, helpReducer }) => {
    return { registrationReducer, helpReducer };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getRegistrationContent());
  }, []);
  const handleLogout = async () => {
    try {
      setLoading(true);
      let res = await dispatch(logOff()).unwrap();
      logout();
      console.log("logout success", res);
      setLoading(false);
    } catch (error) {
      console.log("logout error", error);
      setLoading(false);
    }
  };
  const onChangeName = (event) => {
    setState({ ...state, ["name"]: event.target.value });
  };

  const onChangeBusinessId = (event) => {
    setState({ ...state, ["businessId"]: event.target.value });
  };

  const onChangeAddress = (event) => {
    setState({ ...state, ["address"]: event.target.value });
  };

  const onChangePhone = (event) => {
    const phoneNumber = event.target.validity.valid ? event.target.value : "";
    setState({ ...state, ["phone"]: phoneNumber });
  };

  const register = () => {
    var customer = {
      name: state.name,
      requestedDomains: state.requestedDomains,
      primaryEmail: state.primaryEmail,
      address: state.address,
      businessId: state.businessId,
      phone: state.phone,
      countryCode: state.countryCode.value,
    };

    dispatch(customerRegistration(customer));
  };

  const onChangeCountry = (selectedOption) => {
    if (selectedOption) {
      setState({
        ...state,
        ["countryCode"]: selectedOption,
      });
    } else {
      setState({
        ...state,
        ["countryCode"]: "",
      });
    }
  };

  const onChangeDomains = (event) => {
    // eslint-disable-next-line no-unused-vars
    var re =
      /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
    var domainArray = null;
    let isDomainValid = false;
    if (event.target.value.indexOf(",") > -1) {
      domainArray = event.target.value.split(",");
      domainArray.forEach((domain) => {
        isDomainValid = isValidDomain(domain.trim());
      });
    } else {
      isDomainValid = isValidDomain(event.target.value.trim());
    }

    setState({
      ...state,
      ["requestedDomains"]: event.target.value,
      ["isDomainValid"]: isDomainValid,
    });
  };

  const isValidDomain = (v) => {
    if (!v) return false;
    var re =
      /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
    return re.test(v);
  };

  const { loading, refresh } = reducerState.registrationReducer;
  const { registrationContent } = reducerState.helpReducer;
  let mandatoryStepContent = null;
  let optionStepContent = null;
  if (registrationContent.items) {
    mandatoryStepContent = _.filter(registrationContent.items, function (item) {
      return item.fields?.type === "mandatory";
    })[0]?.fields.content;
    optionStepContent = _.filter(registrationContent.items, function (item) {
      return item.fields.type === "optional";
    })[0]?.fields?.content;
  }
  if (authData && !authData.isNormalUser) {
    return <Navigate to={"/app"} />;
  }
  if (refresh === true) {
    window.location.reload();
  }
  return (
    <>
      <Grid
        container
        direction="row"
        paddingTop={1.1}
        paddingBottom={1.6}
        paddingLeft={2.5}
        paddingRight={4.5}
        justifyContent="space-between"
        alignItems="center"
        className="bg-dark"
      >
        <Grid
          item
          className="text-white fw-bold"
          style={{ fontSize: 15 }}
        ></Grid>
        <Grid item className="text-white">
          <Grid container spacing={2}>
            <Grid item style={{ cursor: "pointer" }}>
              <Feedback />
            </Grid>

            <Grid item>|</Grid>
            <Grid
              item
              style={{ cursor: "pointer" }}
              onClick={() => handleLogout()}
            >
              Logout
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="container-fluid text-start">
        <div className="wrapper">
          <div className="content">
            <div className="content d-flex justify-content-center">
              <div className="w-50 mt-3 boxshadow boxrounded gravitoRegBox gravitoBrandBG">
                <div className="d-flex justify-content-center pb-50 ">
                  <img
                    src={require("../../assets/img/gravito_logo.png")}
                    className="img-fluid logoimage"
                    width="200"
                    height="120"
                    alt=""
                  />
                </div>

                <form autoComplete="off">
                  <Scrollbars
                    autoHide
                    style={{ height: "calc(100vh - 225px)" }}
                  >
                    <div className="col-sm-12">
                      <div className="col-sm-12 gravitoInfo">
                        {mandatoryStepContent ? (
                          <Alert
                            variant="filled"
                            className="m-3"
                            severity="info"
                            color="grey"
                          >
                            {mandatoryStepContent}{" "}
                          </Alert>
                        ) : null}
                        <FormControl className="col-sm-12 mb-3 mt-3">
                          <label className="controlLabel">
                            Domains{" "}
                            <span className="text-muted font-sm">
                              (Enter one or more valid domains ex. gravito.net)
                            </span>
                          </label>
                          <TextField
                            name="requestedDomains"
                            value={state.requestedDomains}
                            className="contentTitle"
                            id="requestedDomains"
                            variant="outlined"
                            required
                            onChange={(e) => onChangeDomains(e)}
                          />
                        </FormControl>
                      </div>

                      <div className="col-sm-12">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.termsAccepted}
                              onChange={(event, checked) =>
                                setState({
                                  ...state,
                                  ["termsAccepted"]: !state.termsAccepted,
                                })
                              }
                              value="I have right to manage and adminster above domain(s)."
                              color="primary"
                            />
                          }
                          label="I have right to manage and adminster above domain(s)."
                        />
                      </div>

                      <div className="col-sm-12 gravitoInfo">
                        {optionStepContent ? (
                          <Alert
                            variant="filled"
                            color="grey"
                            className="m-3"
                            severity="info"
                          >
                            {optionStepContent}
                          </Alert>
                        ) : null}
                        <ExpansionPanel elevation={0} className="mb-30">
                          <ExpansionPanelSummary
                            style={{ padding: 0 }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography>
                              <label className="controlLabel font-lg">
                                Optional Business Details
                              </label>
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-6">
                                  <FormControl className="col-sm-12 mb-20">
                                    <label className="controlLabel">
                                      Business Name
                                    </label>
                                    <TextField
                                      name="businessName"
                                      value={state.name}
                                      className="contentTitle"
                                      id="businessName"
                                      variant="outlined"
                                      required
                                      onChange={(e, data) =>
                                        onChangeName(e, data)
                                      }
                                    />
                                  </FormControl>
                                </div>
                                <div className="col-sm-6">
                                  <FormControl className="col-sm-12 mb-20">
                                    <label className="controlLabel">
                                      Business ID
                                    </label>
                                    <TextField
                                      name="businessId"
                                      value={state.businessId}
                                      className="contentTitle"
                                      id="businessId"
                                      variant="outlined"
                                      required
                                      onChange={(e) => onChangeBusinessId(e)}
                                    />
                                  </FormControl>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-6">
                                  <FormControl className="col-sm-12 mb-20">
                                    <label className="controlLabel">
                                      Country Code
                                    </label>
                                    <Select
                                      name="countryCode"
                                      value={state.countryCode}
                                      onChange={onChangeCountry}
                                      options={coustomdropdownCode}
                                      isClearable
                                      className="basic-multi-select custom-select-height"
                                    ></Select>
                                  </FormControl>
                                </div>
                                <div className="col-sm-6">
                                  <FormControl className="col-sm-12 mb-20">
                                    <label className="controlLabel">
                                      Phone Number
                                    </label>
                                    <TextField
                                      name="phoneNumber"
                                      value={state.phone}
                                      className="contentTitle"
                                      id="phoneNumber"
                                      variant="outlined"
                                      required
                                      onChange={(e) => onChangePhone(e)}
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <FormControl className="col-sm-12 mb-20">
                                    <label className="controlLabel">
                                      Address
                                    </label>
                                    <TextField
                                      multiline
                                      name="address"
                                      value={state.address}
                                      className="contentTitle"
                                      id="address"
                                      variant="outlined"
                                      required
                                      onChange={(e) => onChangeAddress(e)}
                                    />
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>

                      <div className="d-flex justify-content-center">
                        <div className="m-2 col-sm-3">
                          <Button
                            variant="contained"
                            className="btn btn-primary text-white"
                            onClick={(e) => register(e)}
                            disabled={
                              !state.termsAccepted ||
                              state.requestedDomains === "" ||
                              !state.isDomainValid
                            }
                            color="primary"
                          >
                            Register
                          </Button>
                        </div>
                        {/* <div className="m-2 col-sm-3">
                  <Button
                    className="btn btn-warning text-white"
                    color="primary"
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                </div> */}
                      </div>
                    </div>
                  </Scrollbars>
                </form>
                <div className="loader">
                  {loadingLocal && <CircularProgress size={100} />}
                  {loadingLocal && <br />}
                  {loadingLocal && (
                    <span className="pagemsg">Please wait...</span>
                  )}
                </div>

                <div className="loader">
                  {loading && <CircularProgress size={100} />}
                  {loading && <br />}
                  {loading && <span className="pagemsg">Please wait...</span>}
                </div>
              </div>
            </div>
            <div style={{ position: "fixed", bottom: 0, zIndex: -1 }}>
              <img
                src={require("../../assets/illustrations/progress-hd.png")}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const mapStateToProps = ({ registrationReducer, helpReducer }) => {
//   return { registrationReducer, helpReducer };
// };

// export default connect(mapStateToProps, {
//   customerRegistration,
//   getRegistrationContent,
// })(CustomerRegistration);

export default CustomerRegistration;
