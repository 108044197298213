/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import Logo from "../../../assets/images/Eteva_Logo.svg";
import { useThemeContext } from "../../../contexts/theme";
import Menu from "../sidebars/menu";
import Feedback from "../../../components/Feedback";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as _ from "lodash";

import { Grid } from "@mui/material";
import { useAuth } from "../../../contexts/auth";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Apartment, Language } from "@mui/icons-material";
import { createContextHash, getContextObject } from "../../../helpers";

// thunk
import { logOff } from "../../../thunks/auth";
import LoadingOverlay from "../../../components/loadingOverlay";

const domainDependentRoutes = [
  "audiences",
  "contents",
  "campaigns",
  "segments",
  "constellations",
  "campaigndashboard",
];

const NavBar = () => {
  const dispatch = useDispatch();
  const themeObj = useThemeContext();
  const { data: authData, setData: setAuthData, logout: logout } = useAuth();
  const [isDarkMode, changeMode] = useState(false);
  const [openAlert, setAlert] = useState(false);
  const [domainAlert, setDomainAlert] = useState(false);
  const [logoutAlert, setLogoutAlert] = useState(false);
  const [customer, setCustomer] = useState(authData.currentCustomer.id);
  const [domain, setDomain] = useState(authData.currentDomain.id);
  const [loading, setLoading] = useState(false);
  const [t, I18n] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    changeMode(themeObj.theme === "dark");
  }, []);
  useEffect(() => {
    changeMode(themeObj.theme === "dark");
  }, [themeObj]);

  const toggleTheme = () => {
    setTimeout(() => {
      if (themeObj.theme === "light") {
        themeObj.changeTheme("dark");
      } else {
        themeObj.changeTheme("light");
      }
    }, 100);
  };

  function getRouteFromRole(isSelfServiceCustomer) {
    //todo logic to return route from roles
    let routes = [];
    if (isSelfServiceCustomer) {
      routes = [...Menu.selfService];
    } else {
      routes = [...Menu.customerAdmin];
    }
    if (authData.isPartner) {
      routes = [...routes, ...Menu.partner];
    }
    return routes;
  }

  const getThemedTextColor = () => {
    if (themeObj.theme === "light") {
      return "text-dark";
    } else {
      return "text-white";
    }
  };
  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-dark";
    }
  };
  const onFirstlayerClick = (e) => {
    const elemennts = document.getElementsByClassName("nested-parent");
    Array.prototype.forEach.call(elemennts, (ele) => {
      ele.classList.remove("active");
    });
  };
  const nestedLinkClicked = (e) => {
    const elemennts = document.getElementsByClassName("nested-parent");
    Array.prototype.forEach.call(elemennts, (ele) => {
      ele.classList.remove("active");
    });
    e.target.parentElement.parentElement.parentElement.children[0].classList.add(
      "active"
    );
  };
  const changeLanguage = (lnCode) => {
    I18n.changeLanguage(lnCode);
  };
  const onChangeCustomer = (e) => {
    setCustomer(e.target.value);
    setAlert(true);
  };
  const onChangeDomain = (e) => {
    setDomain(e.target.value);
    setDomainAlert(true);
  };

  const onConfirm = () => {
    let currentCustomerObj = _.find(
      authData.mappedCustomers,
      (c) => c.id == customer
    );
    let newAuthData = { ...authData };
    newAuthData.currentCustomer = currentCustomerObj;

    var mappedDomains = [];
    if (currentCustomerObj) {
      var domainList = _.filter(newAuthData.userCustomerDomain, {
        CustomerId: customer,
      });
      if (domainList[0].Domain) {
        domainList[0].Domain.forEach((domain) => {
          mappedDomains.push({
            domain: domain.DomainName,
            id: domain.DomainDocumentId,
            customerId: customer,
            CustomerUniqueId: _.find(
              newAuthData.mappedCustomers,
              (c) => c.id == customer
            ).CustomerUniqueId,
          });
        });
      } else {
        mappedDomains = [];
      }
    }
    newAuthData.mappedDomains = mappedDomains;
    newAuthData.currentDomain =
      mappedDomains.length > 0 ? mappedDomains[0] : "";
    newAuthData.currentCustomer = currentCustomerObj;
    setAuthData(newAuthData);
    setDomain(newAuthData.currentDomain.id);

    let usercontext = getContextObject();
    usercontext.customerId = customer;
    // refresh hash of the context and store it in sessionStorage
    createContextHash(usercontext);
    setAlert(false);
    const elemennts = document.getElementsByClassName("nested-parent");
    Array.prototype.forEach.call(elemennts, (ele) => {
      ele.classList.remove("active");
    });
    navigate("/app");
  };
  const onCancel = () => {
    let oldCustomer = authData.currentCustomer;
    setCustomer(oldCustomer.id);
    setAlert(false);
  };

  const showDomainSelector = () => {
    let currentRoute = location.pathname.split("/")[2];
    return domainDependentRoutes.includes(currentRoute);
  };

  const onConfirmDomain = () => {
    let currentDomainObj = _.find(
      authData.mappedDomains,
      (d) => d.id == domain
    );
    let newAuthData = { ...authData };

    newAuthData.currentDomain = currentDomainObj;
    setAuthData(newAuthData);
    setDomainAlert(false);
  };
  const onCancelDomain = () => {
    let oldDomain = authData.currentDomain;
    setDomain(oldDomain.id);
    setDomainAlert(false);
  };
  const openPreference = () => {
    if (window) {
      if (window.gravitoLightCMP) {
        window.gravitoLightCMP.openPreferences();
      }
    }
  };

  const handleLogout = async () => {
    try {
      setLogoutAlert(false);
      setLoading(true);
      let res = await dispatch(logOff()).unwrap();
      logout();
      setLoading(false);
    } catch (error) {
      console.log("logout error", error);
      setLoading(false);
    }
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const { customerAdmin, isAdmin, isNormalUser, isPartner, currentCustomer } =
    authData;

  return (
    <>
      {loading && <LoadingOverlay />}
      <Grid
        container
        direction="row"
        paddingTop={1.1}
        paddingBottom={1.6}
        paddingLeft={2.5}
        paddingRight={4.5}
        justifyContent="space-between"
        alignItems="center"
        className="bg-dark"
      >
        <Grid item className="text-white fw-bold" style={{ fontSize: 15 }}>
          Welcome,{" "}
          {authData.user.customerDomainList
            ? authData.user.customerDomainList[0].Email
            : ""}{" "}
          ! {getCookie("gravitoassist") ? "(assisted by gravito admin)" : null}
        </Grid>
        <Grid item className="text-white">
          <Grid container spacing={2}>
            <Grid item style={{ cursor: "pointer" }}>
              <Feedback />
            </Grid>
            <Grid item>|</Grid>
            <Grid item style={{ cursor: "pointer" }}>
              <span onClick={() => navigate("/app/manageaccount")}>
                Account
              </span>
            </Grid>
            <Grid item>|</Grid>
            <Grid
              item
              style={{ cursor: "pointer" }}
              onClick={() => setLogoutAlert(true)}
            >
              Logout
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <nav className={`navbar navbar-expand-md`}>
        <div className="container-fluid px-0">
          <a
            onClick={() => navigate("/app/gettingstarted")}
            className={`d-flex align-items-start mb-md-0 me-md-auto ${getThemedTextColor()} text-decoration-none`}
          >
            <span
              className={`fs-4 px-3 py-1 ${
                themeObj.theme === "light" ? "bg-transparent" : "bg-transparent"
              }`}
              style={{ position: "relative" }}
            >
              <img
                style={{
                  height: "30.58px",
                  width: "auto",
                  marginTop: 10,
                  marginBottom: 10,
                }}
                src={require("../../../assets/image/gravito_logo.png")}
                alt="Gravito"
              />
              <img
                className="img-fluid"
                style={{ position: "absolute", left: 15, top: 35, height: 25 }}
                src={require("../../../assets/image/bck.png")}
                alt="shadow"
              />
            </span>
          </a>

          <button
            className={`navbar-toggler ${getThemedTextColor()}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <i style={{ fontSize: "26px" }} className="bi bi-list"></i>
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <div
              style={{ flex: 4 }}
              className="d-flex justify-content-between aling-items-center w-100 ps-2 flex-md-row"
            >
              <ul
                style={{ width: "80%", gap: 10 }}
                className="navbar-nav justify-content-start"
              >
                {getRouteFromRole(
                  authData.currentCustomer.isSelfServiceCustomer
                ).map((item, index) => {
                  if (item.label === "sidebar.cookieSettings") {
                    return (
                      <li key={index} className="nav-item">
                        <div
                          style={{ cursor: "pointer" }}
                          // to={item.path}
                          className={`nav-link text-start navItemTextStyle ${
                            item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                          aria-current="page"
                          onClick={openPreference}
                        >
                          {t(item.label)}
                        </div>
                      </li>
                    );
                  }
                  if (!item.subMenu) {
                    return (
                      <li key={index} className="nav-item">
                        <NavLink
                          to={item.path}
                          className={`nav-link text-start navItemTextStyle ${
                            item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                          aria-current="page"
                          onClick={onFirstlayerClick}
                        >
                          {t(item.label)}
                        </NavLink>
                      </li>
                    );
                  } else {
                    return (
                      <li key={index} className="nav-item dropdown">
                        <a
                          className={`nav-link nested-parent text-start dropdown-toggle navItemTextStyle  ${getThemedTextColor()} ${
                            item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                          to={"#"}
                          id="navbarDropdownMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t(item.label)}
                        </a>
                        <ul
                          style={{ border: "0px", width: "max-content" }}
                          className={`dropdown-menu ${getThemedBgColor()}`}
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          {item.subMenu.map((nestedItem, i) => {
                            return (
                              <li key={`nested-${i}`} className="nav-item">
                                <NavLink
                                  onClick={nestedLinkClicked}
                                  to={nestedItem.path}
                                  className={`nav-link text-start submenu ${getThemedTextColor()} ${
                                    item.vissbleOnSmallScrren
                                      ? ""
                                      : "d-none d-md-block"
                                  }`}
                                  aria-current="page"
                                >
                                  {t(nestedItem.label)}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  }
                })}
              </ul>
              <div className="d-flex" style={{ flex: 1 }}>
                {customer && (
                  <FormControl
                    className="d-flex flex-row align-items-center"
                    variant="standard"
                    sx={{
                      minWidth: 120,
                      marginRight: showDomainSelector() ? "0px" : "15px",
                    }}
                  >
                    <Apartment
                      style={{
                        fontSize: "16px",
                        marginRight: "2px",
                        marginBottom: "2px",
                      }}
                    />
                    {authData.mappedCustomers.length > 1 ? (
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={customer}
                        disableUnderline
                        onChange={onChangeCustomer}
                        label="cusotmer"
                        SelectDisplayProps={{
                          style: { fontWeight: "bolder", fontSize: "14px" },
                          className: "text-secondary",
                        }}
                      >
                        {authData.mappedCustomers.map((c) => {
                          return (
                            <MenuItem
                              style={{ fontSize: "12px" }}
                              className={`${
                                c.id === customer ? "bg-primary text-white" : ""
                              } fw-bolder`}
                              key={c.id}
                              value={c.id}
                            >
                              {c.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      <div className="d-flex flex-row align-items-center">
                        <span
                          style={{ fontWeight: "bolder", fontSize: "14px" }}
                          className="text-secondary"
                        >
                          {
                            authData.mappedCustomers.find(
                              (c) => c.id === customer
                            ).name
                          }
                        </span>
                      </div>
                    )}
                  </FormControl>
                )}
                {showDomainSelector() && (
                  <>
                    <div
                      className={"border-secondary"}
                      style={{
                        height: "40px",
                        borderRightWidth: "3px",
                        borderRightStyle: "solid",
                      }}
                    ></div>
                    <FormControl
                      className="d-flex flex-row align-items-center"
                      variant="standard"
                      sx={{ marginRight: 10, minWidth: 120 }}
                    >
                      <Language
                        style={{
                          fontSize: "16px",
                          marginRight: "5px",
                          marginLeft: "5px",
                          marginBottom: "2px",
                        }}
                      />
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={domain}
                        disableUnderline
                        onChange={onChangeDomain}
                        label="domain"
                        SelectDisplayProps={{
                          style: { fontWeight: "bolder", fontSize: "14px" },
                          className: "text-secondary",
                        }}
                      >
                        {authData.mappedDomains.map((d) => {
                          return (
                            <MenuItem
                              style={{ fontSize: "12px" }}
                              className={`${
                                d.id === domain ? "bg-primary text-white" : ""
                              } fw-bolder`}
                              key={d.id}
                              value={d.id}
                            >
                              {d.domain}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Dialog
        open={openAlert}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ fontSize: "18px" }} id="alert-dialog-title">
          {" "}
          {"ARE YOU SURE, YOU WANT TO SWITCH THE CUSTOMER?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure that all changes are saved
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={onCancel}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            color="dark"
            style={{ color: "white" }}
            onClick={onConfirm}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={domainAlert}
        onClose={onCancelDomain}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ fontSize: "18px" }} id="alert-dialog-title">
          {" "}
          {"ARE YOU SURE, YOU WANT TO SWITCH THE DOMAIN?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure that all changes are saved
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={onCancelDomain}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            color="dark"
            style={{ color: "white" }}
            onClick={onConfirmDomain}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={logoutAlert}
        onClose={() => setLogoutAlert(false)}
        aria-labelledby="logout-alert-title"
        aria-describedby="logout-alert-description"
      >
        <DialogTitle style={{ fontSize: "18px" }} id="logout-alert-title">
          Are you sure you want to logout?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-alert-description">
            Please make sure that all changes are saved
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={() => setLogoutAlert(false)}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            color="dark"
            style={{ color: "white" }}
            onClick={handleLogout}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NavBar;
