import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { getTrendsData } from "../thunks/compareTrends";
import _, { keys } from "lodash";
import moment from "moment";

// initial state
const INIT_STATE = {
  domainList: [],
  trendPayload: null,
  dashboardData: [],
  filterDomains: [],
  filterModel: {
    type: "hour",
    text: "Last 12 hours",
    value: 12,
  },
  profilesStats: {
    new: 0,
    revisits: 0,
    updates: 0,
  },
  domainStats: [],
  browserStats: [],
  opPresent: false,
  mpPresent: false,
  reportType: null,
  filterDays: 0,
  loading: false,
  selectedCustomer: null,
  customers: [],
  noDataStatus: false,
  trendSeries: [],
  trendDays: [],
  groupOneChartData: null,
  groupTwoChartData: null,
};

const checkCMPDataIsEmpty = (cmpChartDataSeries) => {
  try {
    let result = true;
    for (var obj of cmpChartDataSeries) {
      let itemData = obj.data;
      let r = itemData.every((i) => i === 0);
      if (!r) {
        result = false;
        break;
      }
    }
    return result;
  } catch (error) {
    return true;
  }
};

function getChartInformation(cdpData, cmpData) {
  let chartData = {
    series: [],
    options: {
      chart: {
        height: 300,
        type: "bar",
        stacked: true,
        zoom: {
          enabled: false,
        },
        fontFamily: "Poppins, Helvetica Neue, Arial, Helvetica, sans-serif",
      },
      dataLabels: {
        enabled: false,
      },

      title: {
        text: "Observed profile day wise event statistics",
        align: "center",
      },
      //   legend: {
      //     show: false,
      //     tooltipHoverFormatter: function (val, opts) {
      //       return (
      //         val +
      //         " - " +
      //         opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
      //         ""
      //       );
      //     },
      //   },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [],
      },

      colors: ["#FFA100", "#cc8100", "#919191"],
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
  let mpChartData = {
    series: [],
    options: {
      chart: {
        height: 300,
        type: "bar",
        stacked: true,
        zoom: {
          enabled: false,
        },
        fontFamily: "Poppins, Helvetica Neue, Arial, Helvetica, sans-serif",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: "smooth",
      },
      title: {
        text: "Micro profile day wise event statistics",
        align: "center",
      },

      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [],
      },

      colors: ["#FFA100", "#cc8100", "#919191", "#4183c4"],
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
  let cmpChartData = {
    series: [],
    options: {
      chart: {
        height: 300,
        type: "bar",
        stacked: true,
        zoom: {
          enabled: false,
        },
        fontFamily: "Poppins, Helvetica Neue, Arial, Helvetica, sans-serif",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      dataLabels: {
        enabled: false,
      },

      title: {
        text: "CMP day wise event statistics",
        align: "center",
      },

      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: [],
      },

      colors: [
        "#FFA100",
        "#cc8100",
        "#919191",
        "#ffb433",
        "#664000",
        "#747474",
        "#b2b2b2",
        "#494949",
        "#d3d3d3",
      ],

      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  let totalProfilesCreated = 0;
  let totalProfilesFetched = 0;
  let totalProfilesUpdated = 0;
  let totalmpProfilesCreated = 0;
  let totalmpProfilesFetched = 0;
  let totalmpProfilesUpdated = 0;
  let totalmpProfilesMerged = 0;
  let totalProfilesCreatedForTrends = 0;
  let totalProfilesFetchedForTrends = 0;
  let totalProfilesUpdatedForTrends = 0;
  let totalmpProfilesCreatedForTrends = 0;
  let totalmpProfilesFetchedForTrends = 0;
  let totalmpProfilesUpdatedForTrends = 0;
  let totalmpProfilesMergedForTrends = 0;
  let domainsData = [];
  let browserData = [];
  let days = [];
  let newProfiles = [];
  let revisits = [];
  let updates = [];

  // Micro profile details
  let mpCreated = [];
  let mpFetched = [];
  let mpUpdated = [];
  let mpMerged = [];

  // CMP Events
  let cmpDomainsData = [];
  let cmpLoaded = [];
  let cmpLayer1Visible = [];
  let cmpLayer2Visible = [];
  let cmpLayer1OptinAll = [];
  let cmpLayer2OptinAll = [];
  let cmpPreviousOptin = [];
  let cmpLayer1OptoutAll = [];
  let cmpLayer2OptoutAll = [];
  let cmpLayer2OptinSelected = [];

  let totalcmpLoaded = 0;
  let totalcmpLayer1Visible = 0;
  let totalcmpLayer2Visible = 0;
  let totalcmpLayer1OptinAll = 0;
  let totalcmpLayer2OptinAll = 0;
  let totalcmpLayer2OptinSelected = 0;
  let totalcmpPreviousOption = 0;
  let totalcmpLayer1OptoutAll = 0;
  let totalcmpLayer2OptoutAll = 0;

  let totalcmpLoadedForTrends = 0;
  let totalcmpLayer1VisibleForTrends = 0;
  let totalcmpLayer2VisibleForTrends = 0;
  let totalcmpLayer1OptinAllForTrends = 0;
  let totalcmpLayer2OptinAllForTrends = 0;
  let totalcmpLayer2OptinSelectedForTrends = 0;
  let totalcmpPreviousOptionForTrends = 0;
  let totalcmpLayer1OptoutAllForTrends = 0;
  let totalcmpLayer2OptoutAllForTrends = 0;

  let totalcmpOutdated = 0;
  let totalcmpConsentNotSet = 0;
  let totalLayer1ShowSettings = 0;
  let cmpShown = 0;

  var dashboardData = cdpData;
  var eventsData = cmpData;

  // flags to know if observed profile and microprofile data is present.
  var opPresent = false;
  var mpPresent = false;

  var FirstPartyData = {
    usageStats: {},
    trackedEventsStats: {},
  };

  // if the data is returned by the API
  if (dashboardData) {
    days = [];
    // loop through data returned for the given date range.
    dashboardData.forEach((item) => {
      // loop through domainCounts array
      days.push(moment(item.reportDay).format("DD MMM"));
      if (item.domainCounts.length > 0) {
        item.domainCounts.forEach((element, i) => {
          // code for getting the trends Data

          if (element.countByEvent.opProfileCreated) {
            opPresent = true;
            totalProfilesCreatedForTrends =
              totalProfilesCreatedForTrends +
              element.countByEvent.opProfileCreated;
          }
          if (element.countByEvent.opProfileFetched) {
            opPresent = true;
            totalProfilesFetchedForTrends =
              totalProfilesFetchedForTrends +
              element.countByEvent.opProfileFetched;
          }
          if (element.countByEvent.opProfileUpdated) {
            opPresent = true;
            totalProfilesUpdatedForTrends =
              totalProfilesUpdatedForTrends +
              element.countByEvent.opProfileUpdated;
          }

          // new change for microprofile
          if (element.countByEvent.mpProfileCreated) {
            mpPresent = true;
            totalmpProfilesCreatedForTrends =
              totalmpProfilesCreatedForTrends +
              element.countByEvent.mpProfileCreated;
          }
          if (element.countByEvent.mpProfileFetched) {
            mpPresent = true;
            totalmpProfilesFetchedForTrends =
              totalmpProfilesFetchedForTrends +
              element.countByEvent.mpProfileFetched;
          }
          if (element.countByEvent.mpProfileUpdated) {
            mpPresent = true;
            totalmpProfilesUpdatedForTrends =
              totalmpProfilesUpdatedForTrends +
              element.countByEvent.mpProfileUpdated;
          }
          if (element.countByEvent.mpProfileMerged) {
            mpPresent = true;
            totalmpProfilesMergedForTrends =
              totalmpProfilesMergedForTrends +
              element.countByEvent.mpProfileMerged;
          }

          // push the element into domains data array after checking if an entry exists in the array
          // if entry exisits then add the new counts to previous record
          if (_.filter(domainsData, { domain: element.domain }).length > 0) {
            var currentDomainData = _.filter(domainsData, {
              domain: element.domain,
            })[0];
            // count by events items first
            if (element.countByEvent.opProfileCreated) {
              currentDomainData.countByEvent.opProfileCreated
                ? (currentDomainData.countByEvent.opProfileCreated =
                    currentDomainData.countByEvent.opProfileCreated +
                    element.countByEvent.opProfileCreated)
                : (currentDomainData.countByEvent.opProfileCreated =
                    element.countByEvent.opProfileCreated);
            }
            if (element.countByEvent.opProfileFetched) {
              currentDomainData.countByEvent.opProfileFetched
                ? (currentDomainData.countByEvent.opProfileFetched =
                    currentDomainData.countByEvent.opProfileFetched +
                    element.countByEvent.opProfileFetched)
                : (currentDomainData.countByEvent.opProfileFetched =
                    element.countByEvent.opProfileFetched);
            }
            if (element.countByEvent.opProfileUpdated) {
              currentDomainData.countByEvent.opProfileUpdated
                ? (currentDomainData.countByEvent.opProfileUpdated =
                    currentDomainData.countByEvent.opProfileUpdated +
                    element.countByEvent.opProfileUpdated)
                : (currentDomainData.countByEvent.opProfileUpdated =
                    element.countByEvent.opProfileUpdated);
            }

            // change for microprofiles

            if (element.countByEvent.mprofileCreated) {
              currentDomainData.countByEvent.mpProfileCreated
                ? (currentDomainData.countByEvent.mpProfileCreated =
                    currentDomainData.countByEvent.mpProfileCreated +
                    element.countByEvent.mpProfileCreated)
                : (currentDomainData.countByEvent.mpProfileCreated =
                    element.countByEvent.mpProfileCreated);
            }
            if (element.countByEvent.mpProfileFetched) {
              currentDomainData.countByEvent.mpProfileFetched
                ? (currentDomainData.countByEvent.mpProfileFetched =
                    currentDomainData.countByEvent.mpProfileFetched +
                    element.countByEvent.mpProfileFetched)
                : (currentDomainData.countByEvent.mpProfileFetched =
                    element.countByEvent.mpProfileFetched);
            }

            if (element.countByEvent.mpProfileUpdated) {
              currentDomainData.countByEvent.mpProfileUpdated
                ? (currentDomainData.countByEvent.mpProfileUpdated =
                    currentDomainData.countByEvent.mpProfileUpdated +
                    element.countByEvent.mpProfileUpdated)
                : (currentDomainData.countByEvent.mpProfileUpdated =
                    element.countByEvent.mpProfileUpdated);
            }

            if (element.countByEvent.mpProfileMerged) {
              currentDomainData.countByEvent.mpProfileMerged
                ? (currentDomainData.countByEvent.mpProfileMerged =
                    currentDomainData.countByEvent.mpProfileMerged +
                    element.countByEvent.mpProfileMerged)
                : (currentDomainData.countByEvent.mpProfileMerged =
                    element.countByEvent.mpProfileMerged);
            }

            // countByBrowsers
            if (element.countByBrowser) {
              // loop through browser entries
              // if it exists then add to earlier counts else add a new entry
              for (var key in element.countByBrowser) {
                if (element.countByBrowser.hasOwnProperty(key)) {
                  // if browser entry already exists in the list then
                  var prevEntry = _.find(browserData, { browser: key });
                  if (prevEntry) {
                    prevEntry.count =
                      prevEntry.count + element.countByBrowser[key];
                  } else {
                    var item = {
                      browser: key,
                      count: element.countByBrowser[key],
                    };
                    browserData.push(item);
                  }
                }
              }
            }
          }
          // else add the new entry
          else {
            for (var key in element.countByBrowser) {
              if (element.countByBrowser.hasOwnProperty(key)) {
                // if browser entry already exists in the list then
                var prevEntry = _.find(browserData, { browser: key });
                if (prevEntry) {
                  prevEntry.count =
                    prevEntry.count + element.countByBrowser[key];
                } else {
                  var item = {
                    browser: key,
                    count: element.countByBrowser[key],
                  };
                  browserData.push(item);
                }
              }
            }
            domainsData.push(element);
          }

          // check if the profilefetched event is returned
          if (element.countByEvent.opProfileFetched) {
            totalProfilesFetched =
              totalProfilesFetched + element.countByEvent.opProfileFetched;
          }
          // check if the profileCreated event is returned
          if (element.countByEvent.opProfileCreated) {
            totalProfilesCreated =
              totalProfilesCreated + element.countByEvent.opProfileCreated;
          }
          // check if the profileUpdated event is returned
          if (element.countByEvent.opProfileUpdated) {
            totalProfilesUpdated =
              totalProfilesUpdated + element.countByEvent.opProfileUpdated;
          }

          // change for microprofiles
          // check if the mpprofilefetched event is returned
          if (element.countByEvent.mpProfileFetched) {
            totalmpProfilesFetched =
              totalmpProfilesFetched + element.countByEvent.mpProfileFetched;
          }
          // check if the mpprofileCreated event is returned
          if (element.countByEvent.mpProfileCreated) {
            totalmpProfilesCreated =
              totalmpProfilesCreated + element.countByEvent.mpProfileCreated;
          }
          // check if the mpprofileUpdated event is returned
          if (element.countByEvent.mpProfileUpdated) {
            totalmpProfilesUpdated =
              totalmpProfilesUpdated + element.countByEvent.mpProfileUpdated;
          }

          // check if the mpprofileMerged event is returned
          if (element.countByEvent.mpProfileMerged) {
            totalmpProfilesMerged =
              totalmpProfilesMerged + element.countByEvent.mpProfileMerged;
          }
        });

        newProfiles.push(totalProfilesCreatedForTrends);
        revisits.push(totalProfilesFetchedForTrends);
        updates.push(totalProfilesUpdatedForTrends);
        mpCreated.push(totalmpProfilesCreatedForTrends);
        mpFetched.push(totalmpProfilesFetchedForTrends);
        mpUpdated.push(totalmpProfilesUpdatedForTrends);
        mpMerged.push(totalmpProfilesMergedForTrends);
        totalProfilesCreatedForTrends = 0;
        totalProfilesFetchedForTrends = 0;
        totalProfilesUpdatedForTrends = 0;
        //changes for microprofile
        totalmpProfilesCreatedForTrends = 0;
        totalmpProfilesFetchedForTrends = 0;
        totalmpProfilesUpdatedForTrends = 0;
        totalmpProfilesMergedForTrends = 0;
      } else {
        newProfiles.push(totalProfilesCreatedForTrends);
        revisits.push(totalProfilesFetchedForTrends);
        updates.push(totalProfilesUpdatedForTrends);
        mpCreated.push(totalmpProfilesCreatedForTrends);
        mpFetched.push(totalmpProfilesFetchedForTrends);
        mpUpdated.push(totalmpProfilesUpdatedForTrends);
        mpMerged.push(totalmpProfilesMergedForTrends);
      }
    });
  }

  // Sort domain data in descending order.
  domainsData
    .sort(
      (x, y) =>
        (x.countByEvent.opProfileFetched
          ? x.countByEvent.opProfileFetched
          : 0 + x.countByEvent.opProfileCreated
          ? x.countByEvent.opProfileCreated
          : 0) -
        (y.countByEvent.opProfileFetched
          ? y.countByEvent.opProfileFetched
          : 0 + y.countByEvent.opProfileCreated
          ? y.countByEvent.opProfileCreated
          : 0)
    )
    .reverse();

  var profilesStats = {
    new: totalProfilesCreated,
    revisits: totalProfilesFetched,
    updates: totalProfilesUpdated,
    mpCreated: totalmpProfilesCreated,
    mpFetched: totalmpProfilesFetched,
    mpUpdated: totalmpProfilesUpdated,
    mpMerged: totalmpProfilesMerged,
  };

  browserData.sort((x, y) => x.count - y.count).reverse();

  var chartOptions = chartData;

  chartOptions.series = [
    {
      name: "New Profiles",
      data: newProfiles,
    },
    {
      name: "Revisits",
      data: revisits,
    },
    {
      name: "Profile Updates",
      data: updates,
    },
  ];

  chartOptions.options = {
    ...chartOptions.options,
    xaxis: {
      categories: days,
      labels: {
        show: false,
      },
    },
  };

  var mpChartOptions = mpChartData;

  mpChartOptions.series = [
    {
      name: "Fresh Device Profiles",
      data: mpCreated,
    },
    {
      name: "Returning Device Profiles",
      data: mpFetched,
    },
    {
      name: "Profile Updates",
      data: mpUpdated,
    },
    {
      name: "Verified Users",
      data: mpMerged,
    },
  ];

  mpChartOptions.options = {
    ...mpChartOptions.options,
    xaxis: {
      categories: days,
      labels: {
        show: false,
      },
    },
  };

  var cmpChartOptions = cmpChartData;

  cmpChartOptions.series = [
    {
      name: "cmploaded",
      data: cmpLoaded,
    },
    {
      name: "layer1:visible",
      data: cmpLayer1Visible,
    },
    {
      name: "layer2:visible",
      data: cmpLayer2Visible,
    },
    {
      name: "layer1:opt-in:all",
      data: cmpLayer1OptinAll,
    },
    {
      name: "layer2:opt-in:all",
      data: cmpLayer2OptinAll,
    },
    {
      name: "layer2:opt-in:selected",
      data: cmpLayer2OptinSelected,
    },
    {
      name: "opt-in:previously",
      data: cmpPreviousOptin,
    },
    {
      name: "layer1:opt-out:all",
      data: cmpLayer1OptoutAll,
    },
    {
      name: "layer2:opt-out:all",
      data: cmpLayer2OptoutAll,
    },
  ];

  cmpChartOptions.options = {
    ...cmpChartOptions.options,
    xaxis: {
      categories: days,
      labels: {
        show: false,
      },
    },
  };

  // if cmp events are returned
  var cmpInfo = [];
  // if the data is returned by the API
  if (eventsData) {
    days = [];
    // loop through data returned for the given date range.
    eventsData.forEach((item) => {
      // loop through domainCounts array
      days.push(moment(item.reportDay).format("DD MMM"));

      // create a structure for the line charts to be shown in cards.
      var cmpDetails = {
        day: moment(item.reportDay).format("DD MMM"),
        acceptAll: 0,
        acceptSelected: 0,
        rejectAll: 0,
        revisits: 0,
      };

      if (item.domainCounts.length > 0) {
        item.domainCounts.forEach((element) => {
          if (element.countByKeyValues) {
            let Keys = Object.keys(element.countByKeyValues);
            for (var k of Keys) {
              if (Object.keys(FirstPartyData.usageStats).includes(k)) {
                FirstPartyData.usageStats[k].push({
                  day: moment(item.reportDay).format("DD MMM"),
                  data: element.countByKeyValues[k],
                });
              } else {
                FirstPartyData.usageStats[k] = [
                  {
                    day: moment(item.reportDay).format("DD MMM"),
                    data: element.countByKeyValues[k],
                  },
                ];
              }
            }
          }

          if (element.countByTrackedEvents) {
            let Keys = Object.keys(element.countByTrackedEvents);
            for (var k of Keys) {
              if (Object.keys(FirstPartyData.trackedEventsStats).includes(k)) {
                FirstPartyData.trackedEventsStats[k].push({
                  day: moment(item.reportDay).format("DD MMM"),
                  data: element.countByTrackedEvents[k],
                });
              } else {
                FirstPartyData.trackedEventsStats[k] = [
                  {
                    day: moment(item.reportDay).format("DD MMM"),
                    data: element.countByTrackedEvents[k],
                  },
                ];
              }
            }
          }
          // code for getting the trends Data for CMP
          if (element.countByEvent["CMP cmploaded"]) {
            totalcmpLoadedForTrends =
              totalcmpLoadedForTrends + element.countByEvent["CMP cmploaded"];
          }

          if (element.countByEvent["CMP layer1:visible"]) {
            totalcmpLayer1VisibleForTrends =
              totalcmpLayer1VisibleForTrends +
              element.countByEvent["CMP layer1:visible"];
          }
          cmpShown +=
            element.countByEvent["CMP layer1:visible"] ||
            element.countByEvent["CMP consent-not-set"] +
              element.countByEvent["CMP opt-in:previously:outdated"] ||
            0;
          if (element.countByEvent["CMP layer1:opt-in:all"]) {
            totalcmpLayer1OptinAllForTrends =
              totalcmpLayer1OptinAllForTrends +
              element.countByEvent["CMP layer1:opt-in:all"];
          }
          if (element.countByEvent["CMP layer1:opt-out:all"]) {
            totalcmpLayer1OptoutAllForTrends =
              totalcmpLayer1OptoutAllForTrends +
              element.countByEvent["CMP layer1:opt-out:all"];
          }

          if (element.countByEvent["CMP layer2:visible"]) {
            totalcmpLayer2VisibleForTrends =
              totalcmpLayer2VisibleForTrends +
              element.countByEvent["CMP layer2:visible"];
          }
          if (element.countByEvent["CMP layer2:opt-in:selected"]) {
            totalcmpLayer2OptinSelectedForTrends =
              totalcmpLayer2OptinSelectedForTrends +
              element.countByEvent["CMP layer2:opt-in:selected"];
          }

          if (element.countByEvent["CMP layer2:opt-in:all"]) {
            totalcmpLayer2OptinAllForTrends =
              totalcmpLayer2OptinAllForTrends +
              element.countByEvent["CMP layer2:opt-in:all"];
          }

          if (element.countByEvent["CMP layer2:opt-out:all"]) {
            totalcmpLayer2OptoutAllForTrends =
              totalcmpLayer2OptoutAllForTrends +
              element.countByEvent["CMP layer2:opt-out:all"];
          }

          if (element.countByEvent["CMP opt-in:previously"]) {
            totalcmpPreviousOptionForTrends =
              totalcmpPreviousOptionForTrends +
              element.countByEvent["CMP opt-in:previously"];
          }

          // push the element into domains data array after checking if an entry exists in the array
          // if entry exisits then add the new counts to previous record
          if (_.filter(cmpDomainsData, { domain: element.domain }).length > 0) {
            var currentDomainData = _.filter(cmpDomainsData, {
              domain: element.domain,
            })[0];
            // count by events items first
            if (element.countByEvent["CMP cmploaded"]) {
              currentDomainData.countByEvent["CMP cmploaded"]
                ? (currentDomainData.countByEvent["CMP cmploaded"] =
                    currentDomainData.countByEvent["CMP cmploaded"] +
                    element.countByEvent["CMP cmploaded"])
                : (currentDomainData.countByEvent["CMP cmploaded"] =
                    element.countByEvent["CMP cmploaded"]);
            }
            if (element.countByEvent["CMP consent-not-set"]) {
              currentDomainData.countByEvent["CMP consent-not-set"]
                ? (currentDomainData.countByEvent["CMP consent-not-set"] =
                    currentDomainData.countByEvent["CMP consent-not-set"] +
                    element.countByEvent["CMP consent-not-set"])
                : (currentDomainData.countByEvent["CMP consent-not-set"] =
                    element.countByEvent["CMP consent-not-set"]);
            }

            if (element.countByEvent["CMP layer1:visible"]) {
              currentDomainData.countByEvent["CMP layer1:visible"]
                ? (currentDomainData.countByEvent["CMP layer1:visible"] =
                    currentDomainData.countByEvent["CMP layer1:visible"] +
                    element.countByEvent["CMP layer1:visible"])
                : (currentDomainData.countByEvent["CMP layer1:visible"] =
                    element.countByEvent["CMP layer1:visible"]);
            }

            if (element.countByEvent["CMP layer1:opt-in:all"]) {
              currentDomainData.countByEvent["CMP layer1:opt-in:all"]
                ? (currentDomainData.countByEvent["CMP layer1:opt-in:all"] =
                    currentDomainData.countByEvent["CMP layer1:opt-in:all"] +
                    element.countByEvent["CMP layer1:opt-in:all"])
                : (currentDomainData.countByEvent["CMP layer1:opt-in:all"] =
                    element.countByEvent["CMP layer1:opt-in:all"]);
            }

            if (element.countByEvent["CMP layer1:opt-out:all"]) {
              currentDomainData.countByEvent["CMP layer1:opt-out:all"]
                ? (currentDomainData.countByEvent["CMP layer1:opt-out:all"] =
                    currentDomainData.countByEvent["CMP layer1:opt-out:all"] +
                    element.countByEvent["CMP layer1:opt-out:all"])
                : (currentDomainData.countByEvent["CMP layer1:opt-out:all"] =
                    element.countByEvent["CMP layer1:opt-out:all"]);
            }

            if (element.countByEvent["CMP layer2:visible"]) {
              currentDomainData.countByEvent["CMP layer2:visible"]
                ? (currentDomainData.countByEvent["CMP layer2:visible"] =
                    currentDomainData.countByEvent["CMP layer2:visible"] +
                    element.countByEvent["CMP layer2:visible"])
                : (currentDomainData.countByEvent["CMP layer2:visible"] =
                    element.countByEvent["CMP layer2:visible"]);
            }

            if (element.countByEvent["CMP layer2:opt-in:selected"]) {
              currentDomainData.countByEvent["CMP layer2:opt-in:selected"]
                ? (currentDomainData.countByEvent[
                    "CMP layer2:opt-in:selected"
                  ] =
                    currentDomainData.countByEvent[
                      "CMP layer2:opt-in:selected"
                    ] + element.countByEvent["CMP layer2:opt-in:selected"])
                : (currentDomainData.countByEvent[
                    "CMP layer2:opt-in:selected"
                  ] = element.countByEvent["CMP layer2:opt-in:selected"]);
            }

            if (element.countByEvent["CMP layer2:opt-in:all"]) {
              currentDomainData.countByEvent["CMP layer2:opt-in:all"]
                ? (currentDomainData.countByEvent["CMP layer2:opt-in:all"] =
                    currentDomainData.countByEvent["CMP layer2:opt-in:all"] +
                    element.countByEvent["CMP layer2:opt-in:all"])
                : (currentDomainData.countByEvent["CMP layer2:opt-in:all"] =
                    element.countByEvent["CMP layer2:opt-in:all"]);
            }
            if (element.countByEvent["CMP layer2:opt-out:all"]) {
              currentDomainData.countByEvent["CMP layer2:opt-out:all"]
                ? (currentDomainData.countByEvent["CMP layer2:opt-out:all"] =
                    currentDomainData.countByEvent["CMP layer2:opt-out:all"] +
                    element.countByEvent["CMP layer2:opt-out:all"])
                : (currentDomainData.countByEvent["CMP layer2:opt-out:all"] =
                    element.countByEvent["CMP layer2:opt-out:all"]);
            }

            if (element.countByEvent["CMP opt-in:previously"]) {
              currentDomainData.countByEvent["CMP opt-in:previously"]
                ? (currentDomainData.countByEvent["CMP opt-in:previously"] =
                    currentDomainData.countByEvent["CMP opt-in:previously"] +
                    element.countByEvent["CMP opt-in:previously"])
                : (currentDomainData.countByEvent["CMP opt-in:previously"] =
                    element.countByEvent["CMP opt-in:previously"]);
            }

            if (element.countByEvent["CMP opt-in:previously:outdated"]) {
              currentDomainData.countByEvent["CMP opt-in:previously:outdated"]
                ? (currentDomainData.countByEvent[
                    "CMP opt-in:previously:outdated"
                  ] =
                    currentDomainData.countByEvent[
                      "CMP opt-in:previously:outdated"
                    ] + element.countByEvent["CMP opt-in:previously:outdated"])
                : (currentDomainData.countByEvent[
                    "CMP opt-in:previously:outdated"
                  ] = element.countByEvent["CMP opt-in:previously:outdated"]);
            }
          }
          // else add the new entry
          else {
            cmpDomainsData.push(element);
          }

          // check if the cmp loaded event is returned
          if (element.countByEvent["CMP cmploaded"]) {
            totalcmpLoaded =
              totalcmpLoaded + element.countByEvent["CMP cmploaded"];
          }

          if (element.countByEvent["CMP consent-not-set"]) {
            totalcmpConsentNotSet =
              totalcmpConsentNotSet +
              element.countByEvent["CMP consent-not-set"];
          }

          if (element.countByEvent["CMP layer1:visible"]) {
            totalcmpLayer1Visible =
              totalcmpLayer1Visible +
              element.countByEvent["CMP layer1:visible"];
          }

          if (element.countByEvent["CMP opt-in:previously:outdated"]) {
            totalcmpOutdated =
              totalcmpOutdated +
              element.countByEvent["CMP opt-in:previously:outdated"];
          }

          if (element.countByEvent["CMP layer1:opt-in:all"]) {
            totalcmpLayer1OptinAll =
              totalcmpLayer1OptinAll +
              element.countByEvent["CMP layer1:opt-in:all"];
          }
          if (element.countByEvent["CMP layer1:opt-out:all"]) {
            totalcmpLayer1OptoutAll =
              totalcmpLayer1OptoutAll +
              element.countByEvent["CMP layer1:opt-out:all"];
          }
          if (element.countByEvent["CMP layer2:visible"]) {
            totalcmpLayer2Visible =
              totalcmpLayer2Visible +
              element.countByEvent["CMP layer2:visible"];
          }
          if (element.countByEvent["CMP layer2:opt-in:selected"]) {
            totalcmpLayer2OptinSelected =
              totalcmpLayer2OptinSelected +
              element.countByEvent["CMP layer2:opt-in:selected"];
          }
          if (element.countByEvent["CMP layer2:opt-in:all"]) {
            totalcmpLayer2OptinAll =
              totalcmpLayer2OptinAll +
              element.countByEvent["CMP layer2:opt-in:all"];
          }
          if (element.countByEvent["CMP layer2:opt-out:all"]) {
            totalcmpLayer2OptoutAll =
              totalcmpLayer2OptoutAll +
              element.countByEvent["CMP layer2:opt-out:all"];
          }
          if (element.countByEvent["CMP opt-in:previously"]) {
            totalcmpPreviousOption =
              totalcmpPreviousOption +
              element.countByEvent["CMP opt-in:previously"];
          }
        });

        cmpLoaded.push(totalcmpLoadedForTrends);
        cmpLayer1Visible.push(totalcmpLayer1VisibleForTrends);
        cmpLayer1OptinAll.push(totalcmpLayer1OptinAllForTrends);
        cmpLayer1OptoutAll.push(totalcmpLayer1OptoutAllForTrends);
        cmpLayer2OptinAll.push(totalcmpLayer2OptinAllForTrends);
        cmpLayer2OptoutAll.push(totalcmpLayer2OptoutAllForTrends);
        cmpLayer2OptinSelected.push(totalcmpLayer2OptinSelectedForTrends);
        cmpLayer2Visible.push(totalcmpLayer2VisibleForTrends);
        cmpPreviousOptin.push(totalcmpPreviousOptionForTrends);

        // set values of optins, optouts and revists
        cmpDetails.acceptAll =
          totalcmpLayer1OptinAllForTrends + totalcmpLayer2OptinAllForTrends;
        cmpDetails.acceptSelected = totalcmpLayer2OptinSelectedForTrends;
        cmpDetails.rejectAll =
          totalcmpLayer1OptoutAllForTrends + totalcmpLayer2OptoutAllForTrends;
        cmpDetails.revisits = totalcmpPreviousOptionForTrends;

        // Reset values
        totalcmpLoadedForTrends = 0;
        totalcmpLayer1VisibleForTrends = 0;
        totalcmpLayer2VisibleForTrends = 0;
        totalcmpLayer1OptinAllForTrends = 0;
        totalcmpLayer2OptinAllForTrends = 0;
        totalcmpLayer2OptinSelectedForTrends = 0;
        totalcmpPreviousOptionForTrends = 0;
        totalcmpLayer1OptoutAllForTrends = 0;
        totalcmpLayer2OptoutAllForTrends = 0;
      } else {
        cmpLoaded.push(totalcmpLoadedForTrends);
        cmpLayer1Visible.push(totalcmpLayer1VisibleForTrends);
        cmpLayer1OptinAll.push(totalcmpLayer1OptinAllForTrends);
        cmpLayer1OptoutAll.push(totalcmpLayer1OptoutAllForTrends);
        cmpLayer2OptinAll.push(totalcmpLayer2OptinAllForTrends);
        cmpLayer2OptoutAll.push(totalcmpLayer2OptoutAllForTrends);
        cmpLayer2OptinSelected.push(totalcmpLayer2OptinSelectedForTrends);
        cmpLayer2Visible.push(totalcmpLayer2VisibleForTrends);
        cmpPreviousOptin.push(totalcmpPreviousOptionForTrends);

        // set values of optins, optouts and revists
        cmpDetails.acceptAll =
          totalcmpLayer1OptinAllForTrends + totalcmpLayer2OptinAllForTrends;
        cmpDetails.acceptSelected = totalcmpLayer2OptinSelectedForTrends;
        cmpDetails.rejectAll =
          totalcmpLayer1OptoutAllForTrends + totalcmpLayer2OptoutAllForTrends;
        cmpDetails.revisits = totalcmpPreviousOptionForTrends;
      }
      cmpInfo.push(cmpDetails);
    });
  }

  var cmpStats = {
    loaded: totalcmpLoaded,
    layer1Visible: totalcmpLayer1Visible,
    layer1OptinAll: totalcmpLayer1OptinAll,
    layer1OptoutAll: totalcmpLayer1OptoutAll,
    layer2Visible: totalcmpLayer2Visible,
    layer2OptinAll: totalcmpLayer2OptinAll,
    layer2OptoutAll: totalcmpLayer2OptoutAll,
    layer2Selected: totalcmpLayer2OptinSelected,
    previousOptin: totalcmpPreviousOption,
    totalcmpOutdated: totalcmpOutdated,
    totalcmpConsentNotSet: totalcmpConsentNotSet,
    cmpShown: cmpShown,
  };

  return {
    domainStats: domainsData,
    profilesStats: profilesStats,
    browserStats: browserData,
    noDataStatus: domainsData.length === 0 ? true : false,
    chartData: chartOptions,
    mpChartData: mpChartOptions,
    cmpChartData: cmpChartOptions,
    cmpStats: cmpStats,
    cmpDomainsStats: cmpDomainsData,
    nocmpDataStatus: cmpDomainsData.length === 0 ? true : false,
    cmpInfo: cmpInfo,
    opPresent: opPresent,
    mpPresent: mpPresent,
    firstPartyData: FirstPartyData,
    isCMPChartEmpty: checkCMPDataIsEmpty(cmpChartOptions.series),
  };
}

const CompareTrendSlice = createSlice({
  name: "compareTrendSlice",
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTrendsData.fulfilled, (state, action) => {
      var groupOneCdp = action.payload.groupOneCdp;
      var groupTwoCdp = action.payload.groupTwoCdp;
      var groupOneCmp = action.payload.groupOneCmp;
      var groupTwoCmp = action.payload.groupTwoCmp;

      var groupOneData = getChartInformation(groupOneCdp, groupOneCmp);
      var groupTwoData = getChartInformation(groupTwoCdp, groupTwoCmp);

      return {
        ...state,
        loading: false,
        groupOneChartData: groupOneData,
        groupTwoChartData: groupTwoData,
      };
    });

    builder.addCase(getTrendsData.rejected, (state, action) => {});
  },
});

export default CompareTrendSlice.reducer;
