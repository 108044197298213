import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest, getContextObject } from "../helpers";

export const getCustomerList = createAsyncThunk(
  "reporting/getCustomerList",
  async (model, thunkAPI) => {
    try {
      const response = await getAxiosRequest().get("/customer");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getCustomerConsumerReport = createAsyncThunk(
  "reporting/getCustomerConsumerReport",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;

      var payload = {
        noOfDays: 60,
        domainList: [],
      };

      let result = await getAxiosRequest().post(
        `/customer/${currentCustomer}/domaincounts`,
        payload
      );
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getCustomerConsentReport = createAsyncThunk(
  "reporting/getCustomerConsentReport",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;

      let isFliterByDate = Boolean(model.startDate);

      var payload;
      if (isFliterByDate) {
        payload = {
          startDate: model.startDate,
          endDate: model.endDate,
          domainList: [],
        };
      } else {
        payload = {
          noOfDays: model,
          domainList: [],
        };
      }

      let res = await getAxiosRequest().post(
        `/customer/${currentCustomer}/domaineventcounts${
          isFliterByDate ? "fordates" : ""
        }`,
        payload
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getDomainLedgerReport = createAsyncThunk(
  "reporting/getDomainLedgerReport",
  async (domainId, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;

      var config = {};
      config = {
        headers: { DOMAIN_KEY: domainId },
      };
      let res = await getAxiosRequest().get(
        `/customer/${currentCustomer}/ledgerreport`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getDomainEventReport = createAsyncThunk(
  "reporting/getDomainEventReport",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.domain },
      };
      let res = await getAxiosRequest().get(
        `/manage/customer/domainevents?filterType=${model.filterType}&filter=${model.filter}`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
