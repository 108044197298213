import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap";
import "react-notifications/lib/notifications.css";
import "./App.css";
import RootContainer from "./containers/root";
import "./custom-bootstrap.scss";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { NotificationContainer } from "react-notifications";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f07e26 ",
    },
    primaryWhite: {
      main: "#f07e26",
      contrastText: "#ffffff",
    },
    whiteBtn: {
      main: "#ffffff",
    },
    redWhite: {
      main: "#ff0000",
      contrastText: "#ffffff",
    },
    GreenWhite: {
      // main: "#32cd32",
      main: "#99B53C",
      contrastText: "#ffffff",
    },
    BlueWhite: {
      main: "#8fb5c0",
      contrastText: "#ffffff",
    },
    GreyWhite: {
      main: "#a2a2a2",
      contrastText: "#ffffff",
    },
    GreyBlack: {
      main: "#E5E7E8",
      contrastText: "#000000",
    },
    grey: {
      main: "#757474",
      contrastText: "#ffffff",
    },
    lightGrey: {
      main: "rgb(201, 201, 201)",
    },
    dark: {
      main: "black",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "14px",
        },
      },
    },
  },
  typography: {
    fontFamily: " Poppins, Helvetica Neue, Arial, Helvetica, sans-serif ",
    button: {
      textTransform: "none",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <RootContainer />
      </div>
      <NotificationContainer />
    </ThemeProvider>
  );
}
ServiceWorkerRegistration.register();

export default App;
