import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, TextField, Grid, Popover } from "@mui/material";
import LoadingOverlay from "./loadingOverlay";

// thunk
import { sendFeedback } from "../thunks/feedback";

const Feedback = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    message: "",
    anchorEl: null,
  });

  // function to toggle dropdown menu
  const handleOpen = (event) => {
    setState({ ...state, ["anchorEl"]: event.currentTarget });
  };

  // on change language
  const onMessageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ ...state, ["message"]: e.target.value });
  };

  const onSubmit = async () => {
    try {
      if (state.message !== "") {
        setLoading(true);
        await dispatch(sendFeedback(state.message)).unwrap();
      }
      setState({ ...state, ["message"]: "", ["anchorEl"]: null });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <span onClick={handleOpen}>Feedback</span>
      <Popover
        id={"feedbackPopover"}
        open={Boolean(state.anchorEl)}
        anchorEl={state.anchorEl}
        onClose={() => setState({ ["message"]: "", ["anchorEl"]: null })}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {loading && <LoadingOverlay />}
        <Grid
          container
          spacing={2}
          padding={1}
          sx={{
            width: "230px",
          }}
        >
          <Grid item xs={12}>
            <TextField
              // id="outlined-multiline-static"
              variant="standard"
              label="Feedback"
              multiline
              minRows={3}
              maxRows={6}
              fullWidth
              value={state.message}
              onChange={(e) => onMessageChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              id="feedbackSubmit"
              variant="contained"
              color="primaryWhite"
              disabled={state.message === ""}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default Feedback;
