import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers";

// export const getCustomerDashboardData = createAsyncThunk(
//   "customerDashboard/getCustomerDashboardData",
//   async (model, thunkAPI) => {
//     try {
//       var payload = {
//         noOfDays: model.days,
//         domainList: model.filterDomains ? model.filterDomains : [],
//       };

//       const [cdpdata, cmpdata] = await Promise.all([
//         getAxiosRequest().post(
//           `/customer/${model.customerId}/domaincounts`,
//           payload
//         ),
//         getAxiosRequest().post(
//           `/customer/${model.customerId}/domaineventcounts`,
//           payload
//         ),
//       ]);
//       var response = {
//         profiles: cdpdata.data,
//         events: cmpdata.data,
//       };
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const getCustomerDashboardData = createAsyncThunk(
  "customerDashboard/getCustomerDashboardData",
  async (model, thunkAPI) => {
    try {
      var payload = {
        startDate: model.startDate,
        endDate: model.endDate,
        domainList: model.filterDomains ? model.filterDomains : [],
      };

      const [cdpdata, cmpdata] = await Promise.all([
        getAxiosRequest().post(
          `/customer/${model.customerId}/domaincountsfordates`,
          payload
        ),
        getAxiosRequest().post(
          `/customer/${model.customerId}/domaineventcountsfordates`,
          payload
        ),
      ]);
      var response = {
        profiles: cdpdata.data,
        events: cmpdata.data,
      };
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
